import * as React from 'react';
import {createRef, useEffect, useRef} from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './RollingText.scss';
import fadeInElementAnimation from "../../resources/fade-in-element-animation";

const RollingTexts = ({ movingInfo, additionalClasses = [] }) => {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        const time = 200;
        const textJumpDuration = 0.25;
        const jumpWidthDivider = 20;
        let sign = '-';
        let rollingTexts = [];
        let scrollingTexts = [];

        function startRolling() {
            const rollingTextgoLeft = Array.from(document.getElementsByClassName('wrapperRollingText'));
            rollingTextgoLeft.forEach((rolling, index) => {
                if (typeof scrollingTexts[index] !== 'undefined') {
                    scrollingTexts[index].kill();
                }
                const text = rolling.getElementsByClassName('text')[0];
                const width = text.clientWidth;
                rolling.style.marginLeft = `${-width * 3}px`;
                let x = gsap.getProperty(rolling, 'x');
                if (x < -(width * 2)) {
                    x += width * 2;
                }
                if (x > width * 2) {
                    x -= width * 2;
                }
                rollingTexts.push(gsap.fromTo(rolling, {
                    x,
                }, {
                    x: `${sign}=${width}`,
                    ease: 'none',
                    repeat: -1,
                    duration: Math.max(width / time, 10),
                }));
            });
            scrollingTexts = [];
        }
        startRolling();

        function stopRolling() {
            const rollingTextgoLeft = Array.from(document.getElementsByClassName('wrapperRollingText'));
            rollingTextgoLeft.forEach((rolling, index) => {
                if (typeof rollingTexts[index] !== 'undefined') {
                    rollingTexts[index].kill();
                }
                let x = gsap.getProperty(rolling, 'x');
                const text = rolling.getElementsByClassName('text')[0];
                const width = text.clientWidth;
                if (x < -(width * 2)) {
                    x += width * 2;
                }
                if (x > width * 2) {
                    x -= width * 2;
                }
                let tl = gsap.fromTo(rolling,
                    { x, marginLeft: `${-width * 3}px` },
                    { x: `-=${width / jumpWidthDivider}`, marginLeft: `${-width * 3}px`, duration: textJumpDuration });
                let tl2 = gsap.fromTo(rolling,
                    { x, marginLeft: `${-width * 3}px` },
                    { x: `+=${width / jumpWidthDivider}`, marginLeft: `${-width * 3}px`, duration: textJumpDuration });
                const sc = ScrollTrigger.create({
                    trigger: rolling,
                    start: 0,
                    end: 9999,
                    onUpdate: ({ direction, isActive }) => {
                        sign = direction === 1 ? '-' : '+';
                        x = gsap.getProperty(rolling, 'x');
                        if (x < -(width * 2)) {
                            x += width * 2;
                        }
                        if (x > width * 2) {
                            x -= width * 2;
                        }
                        tl = gsap.fromTo(rolling,
                            { x, marginLeft: `${-width * 3}px` },
                            {
                                x: `-=${width / jumpWidthDivider}`,
                                marginLeft: `${-width * 3}px`,
                                duration: textJumpDuration,
                            });
                        tl2 = gsap.fromTo(rolling,
                            { x, marginLeft: `${-width * 3}px` },
                            { x: `+=${width / jumpWidthDivider}`,
                                marginLeft: `${-width * 3}px`,
                                duration: textJumpDuration });
                        if (isActive) {
                            if (direction === 1) {
                                tl2.pause();
                                tl.play();
                            }
                            if (direction === -1) {
                                tl.pause();
                                tl2.play();
                            }
                        } else {
                            tl.pause();
                            tl2.pause();
                        }
                    },
                });
                scrollingTexts.push(sc);
            });
            rollingTexts = [];
        }
        ScrollTrigger.addEventListener('scrollEnd', () => {
            startRolling();
        });
        ScrollTrigger.addEventListener('scrollStart', () => {
            stopRolling();
        });
    }, []);


    return (
        <div className="rolling-texts">
            {movingInfo.map((info) => {
                const rollingTextRef = createRef();
                fadeInElementAnimation([rollingTextRef], '110');
                return (

                    <div ref={rollingTextRef} className={['wrapperRollingText', ...additionalClasses].join(' ')} key={info.text}>
                        {[...Array(10).keys()].map((item) => (
                            <div
                                className="rollingText text"
                                key={item}
                            >
                                {info.text}
                            </div>
                        ))}
                    </div>
                )
            })}
        </div>
    );
};

export default RollingTexts;
