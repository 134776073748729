import * as React from 'react';
import { graphql } from 'gatsby';
import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar/Navbar';
import ScrollTop from '../components/ScrollTop/ScrollTop';
import Footer from '../sections/Footer/Footer';
import HeroText from '../sections/HeroText/HeroText';
import Brands from '../sections/Brands/Brands';
import AboutUs1 from '../assets/about_us1.svg';
import AboutUs1Filled from '../assets/about_us1-filled.svg';
import AboutUs2 from '../assets/about_us2.svg';
import AboutUs2Filled from '../assets/about_us2-filled.svg';
import AboutUsBlack from '../sections/AboutUsBlack/AboutUsBlack';
import Employees from '../sections/Employees/Employees';
import RollingTexts from '../components/RollingTexts/RollingTexts';
import entryAnimation from '../resources/entry-animation';

// markup
const AboutUsTemplate = (props) => {
    const navbarBreakpointRef = useRef();
    const data = props.data.aboutUsPage;
    const dataMainPage = props.data.allMainPage;
    const employees = props.data.allEmployees.nodes;
    const departments = props.data.allDepartment.nodes;
    const mainRef = useRef(null);
    entryAnimation(mainRef, true);


    return (
        <main ref={mainRef} className="about-us-page">
            <div ref={navbarBreakpointRef} className="ref-navbar" />
            <Navbar
                breakpointRef={navbarBreakpointRef}
            />
            <Helmet>
                <title>GetHero</title>
            </Helmet>
            <HeroText
                header={data.header}
                firstParagraph={data.first_hero_paragraph}
                secondParagraph={data.second_hero_paragraph}
            />

            <RollingTexts

                movingInfo={data.first_moving_info}
                additionalClasses={['wrapperRollingText__firstRolling']}
            />
            <AboutUsBlack
                paragraph={data.first_body_paragraph}
                image={AboutUs1}
                imageFilled={AboutUs1Filled}
            />
            <RollingTexts
                movingInfo={data.second_moving_info}
                additionalClasses={['wrapperRollingText__secondRolling']}
            />
            <AboutUsBlack
                paragraph={data.second_body_paragraph}
                image={AboutUs2}
                imageFilled={AboutUs2Filled}
            />
            <Employees departments={departments} employees={employees} />
            <Brands
                photos={dataMainPage.nodes[0].worked_with_logos}
            />
            <Footer />
            <ScrollTop />
        </main>
    );
};

export const pageQuery = graphql`
    query AboutUsSettings($language: String!) {
      aboutUsPage(language: {eq: $language}) {
        first_body_paragraph
        first_hero_paragraph
        header
        second_body_paragraph
        second_hero_paragraph
        second_moving_info {
          text
        }
        first_moving_info {
          text
        }
      }
      allDepartment(sort: {order: ASC, fields: order}) {
        nodes {
          name
          order
          teams {
            name
          }
        }
      }
      allEmployees(sort: {order: ASC, fields: order}) {
        nodes {
          department {
            name
            order
          }
          id
          image {
            localFile {
              publicURL
            }
          }
          name
          order
          position1
          position2
          team {
            name
            order
          }
        }
      }
      allTeam(sort: {fields: order, order: ASC}) {
        nodes {
          name
          order
        }
      }
      allMainPage { 
        nodes {
          worked_with_logos {
            id
            extension
            localFile {
              publicURL
            }
          }
        }
      }
    }`;

export default AboutUsTemplate;
