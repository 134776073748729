import { Accordion, AccordionContext, Card } from 'react-bootstrap';
import * as React from 'react';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Employee from '../../components/Employee/Employee';
import ArrowSingleUp from '../../assets/arrow-up-single-wt.svg';
import ArrowSingleDown from '../../assets/arrow-down-single-wt.svg';
import './Employess.scss';
import { createRef } from 'react';
import fadeInElementAnimation from "../../resources/fade-in-element-animation";

const Employees = ({ departments, employees }) => {
    function CustomToggle({ children, eventKey, callback }) {
        const currentEventKey = React.useContext(AccordionContext);
        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey),
        );
        const isCurrentEventKey = currentEventKey === eventKey;
        return (
            <button
                type="button"
                onClick={decoratedOnClick}
                className="accordion__toggle"
                style={{ backgroundImage: isCurrentEventKey
                    ? `url(${ArrowSingleUp})` : `url(${ArrowSingleDown})` }}
            >
                {children}
            </button>
        );
    }



    return (
        <div className="employees-list">
            <Accordion defaultActiveKey={departments[0].name}>
                {departments.map((department) => {
                    const cardRef = createRef();
                    fadeInElementAnimation([cardRef], 90);
                    return (
                        <div ref={cardRef}>
                            <Card key={department.name}>
                                <CustomToggle
                                    as={Card.Header}
                                    eventKey={department.name}
                                    callback={
                                        () => {
                                            setTimeout(() => {
                                                cardRef.current.scrollIntoView({
                                                    behavior: 'smooth', block: 'start',
                                                });
                                            }, 300);
                                        }
                                    }
                                >
                                    <h3 className="toggle-header employees-list__header">{department.name}</h3>
                                </CustomToggle>
                                <Accordion.Collapse eventKey={department.name}>
                                    <Card.Body>
                                        {department.teams && department.teams.length
                                            ? department.teams.map((team) => (
                                                <div key={team.name}>
                                                    <div className="employees-list__team-header">{team.name}</div>
                                                    <div className="employees-wrapper">
                                                        {employees
                                                            .filter(
                                                                (employee) => employee.department[0].name === department.name
                                                                    && employee.team[0].name === team.name,
                                                            ).map((employee) => (
                                                                <Employee employee={employee} key={employee.id} />
                                                            ))}
                                                    </div>
                                                </div>
                                            ))
                                            : (
                                                <div className="employees-wrapper">
                                                    {employees.filter(
                                                        (employee) => employee.department[0].name === department.name,
                                                    ).map((employee) => (
                                                        <Employee employee={employee} key={employee.id} />
                                                    ))}
                                                </div>
                                            )}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </div>
                    );
                })}
            </Accordion>
        </div>
    );
};

export default Employees;
