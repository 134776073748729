import * as React from 'react';
import './Employee.scss';

const Employee = ({ employee }) => (
    <div className="employee-component">
        <img src={employee.image[0].localFile.publicURL} alt={employee.name} className="employee-component__image" />
        <h4 className="employee-component__name">{employee.name}</h4>
        <p className="employee-component__position">{employee.position1}</p>
        <p className="employee-component__position">{employee.position2}</p>
    </div>
);

export default Employee;
