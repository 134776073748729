import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import './AboutUsBlack.scss';
import clsx from 'clsx';

function AboutUsBlack({
    header,
    paragraph,
    scrollTo,
    image,
    imageFilled,
    secondParagraph,
    yellow = false,
    topRightPosition = false,
}) {
    const cover = useRef(null);
    const emptyHashtagSection = useRef(null);
    const filledHashtagSection = useRef(null);
    const sectionContent = useRef(null);
    const startingPercent = '50';

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.fromTo(
            cover.current,
            {},
            {
                scrollTrigger: {
                    trigger: emptyHashtagSection.current,
                    start: 'top top',
                    end: `${startingPercent}% -30%`,
                    pin: filledHashtagSection.current,
                },
            }
        );
        gsap.fromTo(
            cover.current,
            {},
            {
                scrollTrigger: {
                    trigger: emptyHashtagSection.current,
                    start: 'top top',
                    end: `${startingPercent}% -30%`,
                    pin: emptyHashtagSection.current,
                },
            }
        );
    }, []);

    return (
        <div
            className="about-us-black-section-wrapper"
            id={scrollTo ? 'arrow-down-position' : ''}
        >
            <section
                ref={emptyHashtagSection}
                className="about-us-black-section--empty"
            >
                <img
                    className={clsx(
                        topRightPosition && 'topRightPosition-image',
                        'about-us-black-section__image'
                    )}
                    src={image}
                />
            </section>
            <section
                ref={filledHashtagSection}
                className={clsx(
                    yellow && 'yellow-bg',
                    'about-us-black-section--outlined'
                )}
            >
                <img
                    className={clsx(
                        topRightPosition && 'topRightPosition-image',
                        'about-us-black-section__image'
                    )}
                    src={imageFilled}
                />
            </section>

            <section
                ref={cover}
                className={clsx(
                    yellow && 'yellow-bg',
                    'about-us-black-section--cover'
                )}
            />
            <section
                ref={sectionContent}
                className={clsx(
                    yellow && 'text-black',
                    'about-us-black-section--filled'
                )}
            >
                {header && (
                    <h2 className="about-us-section__header">{header}</h2>
                )}
                <div
                    className={clsx(
                        topRightPosition && 'desc-space',
                        'about-us-black-section__description'
                    )}
                >
                    <p className="description-paragraph">{paragraph}</p>
                    {secondParagraph && (
                        <p className="description-paragraph">
                            {secondParagraph}
                        </p>
                    )}
                </div>
            </section>
            <div />
        </div>
    );
}

export default AboutUsBlack;
