import React, {useEffect, useRef} from 'react';
import './Brands.scss';
import gsap from 'gsap';
import fadeInElementAnimation from "../../resources/fade-in-element-animation";

const Slider = ({ photos, _key }) => photos.map((item) => (
    <div className="brands-section__brands__slider__logo" key={`Brand-${item.id}-${_key}`}>
        <img
            alt="Brand"
            src={item.localFile.publicURL}
        />
    </div>
));

const Brands = ({ photos }) => {
    useEffect(() => {
        const time = 100;
        const rollingTextgoLeft = document.getElementsByClassName('brands-section__brands__slider');

        function startRolling() {
            Array.from(rollingTextgoLeft).forEach((rolling) => {
                const text = rolling.getElementsByClassName('brands-section__brands__slider__item')[0];
                const width = text.clientWidth;
                gsap.to(rolling, {
                    x: `-=${width}`,
                    ease: 'none',
                    repeat: -1,
                    duration: width / time,
                });
            });
        }
        if (document.readyState === 'complete') {
            startRolling();
        } else {
            window.addEventListener('load', startRolling);
        }
    }, []);
    const brandsRef = useRef(null);

    fadeInElementAnimation([brandsRef]);

    return (
        <section ref={brandsRef} className="brands-section">
            <span className="section-name section-name--white">Z KIM PRACOWALIŚMY</span>
            <div className="brands-section__brands">
                <div className="brands-section__brands__slider">
                    {[...Array(2).keys()].map((item) => (
                        <div className="brands-section__brands__slider__item" key={item}>
                            {photos && photos.length > 0 && (
                                <Slider photos={photos} _key={item} />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Brands;
